import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

export function useIsXs() {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.only('xs'))
}

export function useIsSm() {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.down('md'))
}
