import { adminApi } from './api.constants'
import { DEFAULT_LIST_OPTIONS, unwrapResponse } from './api.internal'
import { buildFiltersQueryKey, ListOptions, queryClient } from './api.methods'

export function getListAdminCompaniesOptions(options: ListOptions = DEFAULT_LIST_OPTIONS, filters = {}) {
  return {
    queryKey: ['admin','companies', options.offset, options.limit, options.query, ...buildFiltersQueryKey(filters)],
    queryFn: () => unwrapResponse(adminApi.adminCompaniesQueryMany({ ...options, ...filters }))
  }
}

export function listAdminCompanies(options: ListOptions = DEFAULT_LIST_OPTIONS, filters = {}) {
  return queryClient.ensureQueryData(getListAdminCompaniesOptions(options, filters))
}
