import React from 'react'
import { useLocation } from 'react-router-dom'
import Link from '@mui/material/Link'
import { styled } from '@mui/material/styles'
import generateUtilityClasses from '@mui/utils/generateUtilityClasses'
import clsx from 'clsx'

export interface NavLinkProps {
  to: string
  exact?: boolean
}

export const navLinkClasses = generateUtilityClasses('NavLink', ['active'])

const StyledLink = styled(Link)(({ theme }) => ({
  position: 'relative',
  color: theme.palette.text.primary,
  fontWeight: 400,
  textDecoration: 'none',

  [`&.${navLinkClasses.active}`]: {
    fontWeight: 700,

    '&:before': {
      position: 'absolute',
      left: theme.spacing(-1.5),
      top: 4,
      fontSize: 32,
      lineHeight: `${theme.typography.body1.fontSize}px`,
      content: '"\\00B7"'
    }
  }
}))

export default function NavLink({ children, to, exact, ...rest }: React.PropsWithChildren<NavLinkProps>) {
  const location = useLocation()

  const isActive = React.useMemo(() => (
    (!exact && location.pathname.startsWith(to))
    || (exact && location.pathname === to)
  ), [exact, location.pathname, to])

  return (
    <StyledLink
      href={to}
      className={clsx({
        [navLinkClasses.active]: isActive
      })}
      {...rest}>
      {children}
    </StyledLink>

  )
}
