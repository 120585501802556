import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Action, Claim } from '@oi/constants/auth'
import { Building4LineIcon, User3LineIcon } from '@oi/react/components/icons'
import { useAbility } from '@oi/react/modules/auth'

export function useMenuItems(showMenuTutorial = false) {
  return [{
    key: 'menu.user.information',
    icon: <User3LineIcon fontSize={'small'} />,
    to: '/account',
    selected: showMenuTutorial,
    label: (
      <FormattedMessage
        defaultMessage={'My user information'}
        id={'menu.user.information'} />
    )
  }, {
    key: 'menu.user.company',
    icon: <Building4LineIcon fontSize={'small'} />,
    to: '/company/info',
    selected: false,
    label: (
      <FormattedMessage
        defaultMessage={'My company details'}
        id={'menu.user.company'} />
    )
  }
    // {
    //   key: 'menu.user.accounting',
    //   icon: <WindowLineIcon fontSize={'small'} />,
    //   to: '/credit-checks',
    //   selected: false,
    //   label: (
    //     <FormattedMessage
    //       defaultMessage={'Link accounting system'}
    //       id={'menu.user.accounting'} />
    //   )
    // }
  ]
}

interface LinkItem {
  id: string
  to: string
  exact: boolean
  label: React.ReactNode
}

export function useLinkItems(): Array<LinkItem> {
  const ability = useAbility()

  return [
    {
      id: 'dashboard',
      to: '/',
      exact: true,
      label: (
        <FormattedMessage
          defaultMessage={'Dashboard'}
          id={'menu.dashboard'} />
      )
    },
    {
      id: 'credit-cases',
      to: '/credit-cases',
      exact: false,
      label: (
        <FormattedMessage
          defaultMessage={'Credit cases'}
          id={'menu.credit-cases'} />
      )
    },
    {
      id: 'credit-checks',
      to: '/credit-checks',
      exact: false,
      label: (
        <FormattedMessage
          defaultMessage={'Credit checks'}
          id={'menu.credit-checks'} />
      )
    },
    ability.can(Action.Read, Claim.Admin) && {
      id: 'admin-companies',
      to: '/admin/companies',
      exact: false,
      label: (
        <FormattedMessage
          defaultMessage={'Companies'}
          id={'menu.admin.companies'} />
      )
    }
  ].filter(Boolean) as Array<LinkItem>
}
