import React from 'react'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import NavLink from '@oi/react/components/nav-link'

import { useLinkItems } from './header-items.hooks'

export const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}))

export default function HeaderNav() {
  const linkItems = useLinkItems()

  return (
    <StyledStack
      alignItems={'center'}
      direction={'row'}
      gap={{ xs: 1, md: 5 }}>
      {linkItems.map(({ id, to, exact, label }) => (
        <NavLink
          key={to}
          data-test-id={id}
          exact={exact}
          to={to}>
          {label}
        </NavLink>
      ))}
    </StyledStack>
  )
}
