import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useRouteError } from 'react-router-dom'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { LogoColorIcon } from '@oi/react/components/icons'
import { useOnMountEffect } from '@oi/react/modules/utils'

import { errorReporter } from './error-boundary.component'

export interface ErrorBoundaryFallbackProps {
  withGoToDashboard?: boolean
}

export default function ErrorBoundaryFallback({ withGoToDashboard }: ErrorBoundaryFallbackProps) {
  const error = useRouteError()

  useOnMountEffect(() => {
    errorReporter.report(error as Error)
  })

  return (
    <Stack
      alignItems={'center'}
      display={'flex'}
      flexGrow={1}
      gap={6}
      justifyContent={'center'}
      minHeight={'100vh'}>
      <Stack
        gap={2}
        maxWidth={500}
        p={3}
        width={'100%'}>
        <Stack
          alignItems={'center'}
          mb={4}
          width={'100%'}>
          <LogoColorIcon />
        </Stack>
        <div>
          <Typography
            textAlign={'center'}
            variant={'h1'}
            gutterBottom>
            <FormattedMessage
              defaultMessage={'Oops!'}
              id={'error-boundary.title'} />
          </Typography>

          <Typography
            textAlign={'center'}
            variant={'body1'}>
            <FormattedMessage
              defaultMessage={'We\'re sorry but there seems to be an temporary glitch in the system. Our administrators where already notified and we\'ll do our best to fix the problem as soon as possible.'}
              id={'error-boundary.explain'} />
          </Typography>
        </div>
        <Grid
          gap={3}
          justifyContent={'center'}
          sx={{ mt: 2 }}
          container>
          {withGoToDashboard && (
            <Button
              color={'secondary'}
              variant={'contained'}
              onClick={() => {
                window.location.replace('/')
              }}>
              <FormattedMessage
                defaultMessage={'Dashboard'}
                id={'btn.dashboard'} />
            </Button>
          )}

          <Button
            color={'primary'}
            variant={'contained'}
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              window.location.reload(true)
            }}>
            <FormattedMessage
              defaultMessage={'Refresh page'}
              id={'btn.refresh-page'} />
          </Button>
        </Grid>
      </Stack>
    </Stack>
  )
}
