import React from 'react'

let observer: ResizeObserver

export type ResizeObserverCallback = (target: ResizeObserverEntry) => void

const callbackMap = new WeakMap<Element, ResizeObserverCallback>()

export function useResizeObserver<E extends Element>(
  ref: React.RefObject<E | null> | null,
  callback: ResizeObserverCallback,
  options?: ResizeObserverOptions
) {
  React.useEffect(() => {
    if (ref === null) {
      return () => {
        // Do nothing
      }
    }

    if (ref.current) {
      callbackMap.set(ref.current, callback)
    }

    if (!observer) {
      observer = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          const targetCallback = callbackMap.get(entry.target)

          if (targetCallback) {
            targetCallback(entry)
          }
        })
      })
    }

    const node = ref.current

    if (node) {
      observer.observe(node, options)
    }

    return () => {
      if (node) {
        observer.unobserve(node)
      }
    }
  }, [callback, options, ref])
}
